.est-section {
    color: #434A54;
    background-color: #e7eef7;
    font-family: 'Lato', sans-serif;
}

.est-header-main {
    position: fixed;
    top: 0;
    z-index: 10;
}

.est-footer-main {
    position: fixed;
    bottom: 0;
    z-index: 10;
}

.copyright-main {
    color: #0083f5;
    text-decoration: none;
}

.copyright-main:hover {
    color: #1b1b1b;
}

.show-grid {
    user-select: none;
    cursor: pointer;
}

.shadow-main {
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.checked-main {
    cursor: pointer;
}

.checked-main-1 {
    outline: 10px solid #7BE891;
    outline-offset: initial;
    cursor: pointer;
}

.checked-container {
    position: relative;
}

.checked-container::after {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    top: 5%;
    font-size: 30px;
    color: #fff;
    content: "✔";
    border-radius: 50%;
    background-color: #7BE891;
}

.bg-android {
    background: #00A0E8;
}

.bg-ios {
    background: #467ca8;
}

.bg-web {
    background: #00BFD8;
}

.cursor {
    cursor: pointer;
}

.cost-heading {
    font-size: 43px;
    font-weight: 700;
    line-height: 1.1;
    color: #434A54;
}

.sub-heading {
    font-size: 14px;
    line-height: 1.42857143;
}

.disclaimer {
    margin-top: 10px;
    color: #999;
    font-size: 0.7em;
}

.btn-save-est {
    background: #467ca8 !important;
}

.btn-save-est:hover, .btn-save-est:active {
    background: #355d7e !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.swal-text {
    font-size: 1.1rem;
    color: #1b1b1b;
}

.swal-icon {
    margin-bottom: 10px !important;
}

.swal-title {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 2.5rem;
    color: #1b1b1b;
}

.swal-button {
    background-color: #00BFD8;
    outline: none;
}

.swal-button:hover, .swal-button:active {
    outline: none;
    background-color: #02b9d2 !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.est-form-control:focus,
.est-form-control:active {
    border-color: #467ca8 !important;
    box-shadow: none !important;
}