.main-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.52);
    z-index: 100;
}

.outer {
    background: #44b9fc;
    height: 100px;
    width: 100px;
    border-radius: 15px;
}

.middle {
    height: 60px;
    width: 60px;
    margin: auto;
    position: relative;
    top: 20px;
    border-radius: 50%;
    background-image: linear-gradient(150deg, transparent 50%, #154e7d 50%), linear-gradient(90deg, #154e7d 50%, white 50%);
    animation: rotation 1200ms infinite linear;
    transform-origin: 50% 50%;
    animation-timing-function: ease;
}

.inner {
    background: #44b9fc;
    height: 48px;
    width: 48px;
    margin: auto;
    position: relative;
    top: 6px;
    border-radius: 50%;
}

@keyframes rotation {
    from {
        transform: rotate(270deg);
    }
    to {
        transform: rotate(630deg);
    }
}