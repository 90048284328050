.loader-main {
    background-color: #fff;
    height: 100vh;
    transform: translateX(-5%)
}

.zero {
    height: 0
}

.loader span {
    display: block;
    position: absolute;
    font-size: 3.3rem;
    color: #000;
    font-weight: 500;
    opacity: 0;
    transform: translateY(100%);
    animation: count 3s
}

.loader span:nth-child(1) {
    animation-delay: 0s
}

.loader span:nth-child(2) {
    animation-delay: .8s
}

.loader span:nth-child(3) {
    animation-delay: 1.6s
}

.loader span:nth-child(4) {
    animation-delay: 2.4s
}

.loader span:nth-child(5) {
    animation-delay: 3.2s
}

.loader span:nth-child(6) {
    animation-delay: 4s
}

.loader span:nth-child(7) {
    animation-delay: 4.8s
}

.disap_anim {
    animation: count1 1s;
    transition: all ease
}

@keyframes count1 {
    0% {
        height: 100vh
    }
    100% {
        height: 0
    }
}

@keyframes count {
    0% {
        opacity: 0;
        transform: translateY(100%)
    }
    12% {
        opacity: 1;
        transform: translateY(-50%)
    }
    16% {
        opacity: 0;
        transform: translateY(-100%)
    }
}

@media all and (max-width: 820px) {
    .loader-main {
        transform: translateX(-17%)
    }
}

@media all and (max-width: 768px) {
    .loader-main {
        transform: translateX(-20%)
    }

    .loader span {
        font-size: 3rem
    }
}

@media all and (max-width: 576px) {
    .loader span {
        font-size: 1.5rem
    }

    .preLoader-logo_img {
        width: 100%
    }

    .loader-main {
        transform: translateX(-18%)
    }

    .loader-sub {
        width: 200px
    }
}

@media all and (max-width: 375px) {
    .loader span {
        font-size: 1.3rem
    }

    .preLoader-logo_img {
        width: 100%
    }

    .loader-sub {
        width: 180px
    }
}

@media all and (max-width: 320px) {
    .loader span {
        font-size: 1.1rem
    }

    .preLoader-logo_img {
        width: 100%
    }

    .loader-sub {
        width: 160px
    }
}