@font-face {
    font-family: bariolreg;
    src: url(./Assets/fonts/bariol_regular-webfont.woff) format('woff');
    unicode-range: U+0020-007F
}

@font-face {
    font-family: bariolbold;
    src: url(./Assets/fonts/bariol_bold-webfont.woff) format('woff');
    unicode-range: U+0020-007F
}

body {
    font-family: bariolreg !important
}

.heading-bold {
    font-family: bariolbold !important
}

.copyright-main {
    color: #0083f5;
    text-decoration: none;
}

.copyright-main:hover {
    color: #1b1b1b;
}

body::-webkit-scrollbar-track {
    background-color: transparent;
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    /*background-image: linear-gradient(152deg, #00bfd8 42%, #0083f5 100%);*/
    background-color: #babac0;
}

